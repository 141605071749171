import React from 'react';
import { Box, Image } from '@mantine/core';
import { Link } from 'react-router-dom';

const PageTitle = ({ uuid, title, logo }) => {
    const isAuth = localStorage.getItem('isAuth' || false);

    return <Box className='w-100 mb-3'>
        {logo &&
            <>
                <Link to={`/${isAuth && JSON.parse(isAuth.toLowerCase()) ? `?auth=${uuid}` : `?machineUUID=${uuid}`}`} state={{ uuid }}>
                    <Image
                        style={{
                            width: '110px',
                            margin: '28px auto 10px auto'
                        }}
                        src={`${logo}`}
                        alt=""
                    />
                </Link>
                <hr className='mb-5' style={{
                    height: '1rem',
                    backgroundColor: '#1D2939',
                    opacity: 1
                }} />
            </>
        }
        {title && <h6 className='text-uppercase text-center m-auto fs-36 fw-500'>{title}</h6>}
    </Box>
}

export default PageTitle;
