import React, { useState, useEffect } from 'react';
import { Container, Box, Image, Text, Title, Button, List } from '@mantine/core';
import { Form, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBackSharp } from "react-icons/io5";
import moment from 'moment';

// Components
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Footer from '../../components/Footer/Footer';

// Utility Service
import { getCountryCode, getCurrencyText, getFeeFromBookingAmount } from '../../helpers';

// API-Service
import { bookingService } from '../../service/api.service';

// Images
import { placeHolder } from '../../assets/images';

const Booking = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { sports_facility_id, court_nos, date, start, no_of_hours, total_expected_cost, sports_name, sports_thumb, sports_end_time, sports_start_time, sports_start_bookingAmPm, uuid, facilitiesTitle, courts, timeSlots, id, isLockedBookingValue, booking, complex, complexImage } = location.state;

    const [loading, setLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [notValidated, setNotValidated] = useState(false);
    const [isLockedBooking, setIsLockedBooking] = useState(isLockedBookingValue || false);
    const [username, setUserName] = useState('');
    const [countryCode, setCountryCode] = useState(getCountryCode(complex?.info?.country));
    const [emailNotValidated, setEmailNotValidated] = useState(false);
    const [usernameNotValidated, setUsernameNotValidated] = useState(false);

    const imageArray = complex?.images;

    useEffect(() => {
        setLoading(false);
        setIsLockedBooking(true);
        if (isLockedBooking) {
            setLoading(false);
        }
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, [isLockedBooking]);

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            if (phoneNumber === '' || phoneNumber?.length < 8) {
                setNotValidated(true);
            }

            if (email === '') {
                setEmailNotValidated(true);
            }
            if (username === '') {
                setUsernameNotValidated(true);
            }
            const firstChar = phoneNumber.substring(0, 1);
            let phone = phoneNumber;
            if (firstChar === '0') {
                phone = phoneNumber.substring(1);
            }

            if (isLockedBooking && phone !== '' && phone?.length >= 8 && email !== '') {
                const result = await bookingService({
                    sports_facility_id,
                    court_nos,
                    date,
                    start,
                    no_of_hours,
                    user_email: email,
                    user_phone: countryCode + phone,
                    user_name: username,
                    uuid,
                });
                if (result?.data?.success === 1) {
                    setLoading(false);
                    /* Payment methods
                    "Cash/Terminal/Ipay/"
                    */
                    navigate('/payment', {
                        state: {
                            desc: `Kiosk-${result.data.data.id}-${sports_name}-${date}-${start}`,
                            refNo: result?.data?.data?.id,
                            price: result?.data?.data?.final_amount,
                            paymentUrl: result?.data?.data?.payment_url,
                            remark: 'AFA-KIOSK',
                            paymentMethod: 'Ipay',
                            sports_facility_id,
                            court_nos,
                            date,
                            start,
                            no_of_hours,
                            total_expected_cost,
                            sports_name,
                            sports_thumb,
                            sports_end_time,
                            sports_start_time,
                            sports_start_bookingAmPm,
                            phoneNumber: countryCode + phone,
                            uuid,
                            complexImage,
                            facilitiesTitle,
                            courts,
                            timeSlots,
                            id,
                            isLockedBookingValue: isLockedBooking,
                        },
                    });
                } else {
                    setLoading(false);
                    navigate('/error', {
                        state: {
                            sports_error_msg: result?.data?.message,
                            sports_facility_id,
                            court_nos,
                            date,
                            start,
                            no_of_hours,
                            total_expected_cost,
                            sports_name,
                            sports_thumb,
                            sports_end_time,
                            sports_start_time,
                            sports_start_bookingAmPm,
                            phoneNumber: countryCode + phone,
                            uuid,
                            facilitiesTitle,
                            courts,
                            timeSlots,
                            id,
                            isLockedBookingValue: isLockedBooking,
                        },
                    });
                }
            }
        } catch (error) {
            const firstChar = phoneNumber.substring(0, 1);
            let phone = phoneNumber;
            if (firstChar === '0') {
                phone = phoneNumber.substring(1);
            }
            setLoading(false);
            navigate('/error', {
                state: {
                    sports_error_msg: error || error?.response?.data?.message,
                    sports_facility_id,
                    court_nos,
                    date,
                    start,
                    no_of_hours,
                    total_expected_cost,
                    sports_name,
                    sports_thumb,
                    sports_end_time,
                    sports_start_time,
                    sports_start_bookingAmPm,
                    phoneNumber: countryCode + phone,
                    uuid,
                    facilitiesTitle,
                    courts,
                    timeSlots,
                    id,
                    isLockedBookingValue: isLockedBooking,
                },
            });
        }
    };

    const handlePhoneNumber = (event) => {
        setNotValidated(false);
        setPhoneNumber(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setNotValidated(false);
        setUserName(event.target.value);
    };

    const handleEmail = (event) => {
        setNotValidated(false);
        setEmail(event.target.value);
    };

    return <Container fluid className='px-0' size="md" style={{
        background: "#F7F7F7"
    }}>
        <PageTitle uuid={uuid} title="" logo={imageArray[0].url || complexImage} />
        <Container className='mb-5' size="md" px="md">
            <Container size="md" px="md">
                <Container className='p-5' sx={() => ({
                    background: 'white'
                })} size="md" px="md">
                    {!uuid && <p className='m-auto text-center fs-40 fw-500 booking-title'>Empty/Invalid UUID</p>}
                    {uuid && loading ? (
                        <Loader />
                    ) : (
                        <Box>
                            <Box className='d-flex align-items-center mb-3'>
                                <IoArrowBackSharp role='button' size={30} className='text-dark' onClick={() => navigate(-1, { state: { uuid } })} />
                                <p className='fs-30 fw-bold booking-title mb-0 ms-3'>Booking Details</p>
                            </Box>
                            <Image className='booking-image' src={sports_thumb[0] ? sports_thumb[0] : placeHolder} alt="" />
                            <p className='fs-18 fw-600 mt-3 booking-title'>{sports_name}</p>
                            <Box>
                                <Box className='d-flex justify-content-between my-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        Date
                                    </Text>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#1C1C1C'
                                        })}
                                    >
                                        {moment(date).format('Do MMM YYYY')}
                                    </Text>
                                </Box>
                                <Box className='d-flex justify-content-between my-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        Start Time
                                    </Text>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#1C1C1C'
                                        })}
                                    >
                                        {sports_start_time}
                                        {sports_start_time.includes('AM') || sports_start_time.includes('PM') ? ' ' : ` ${sports_start_bookingAmPm}`}
                                    </Text>
                                </Box>
                                <Box className='d-flex justify-content-between my-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        End Time
                                    </Text>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#1C1C1C'
                                        })}
                                    >
                                        {sports_end_time}
                                    </Text>
                                </Box>
                                <Box className='d-flex justify-content-between my-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        Courts
                                    </Text>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#1C1C1C'
                                        })}
                                    >
                                        {court_nos.join(', ')}
                                    </Text>
                                </Box>
                                <hr />
                                <Box className='d-flex justify-content-between my-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        Booking Cost
                                    </Text>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#1C1C1C'
                                        })}
                                    >
                                        {`${getCurrencyText(complex?.info?.country)}${parseFloat(booking?.final_amount).toFixed(2)}`}
                                    </Text>
                                </Box>
                                <Box className='d-flex justify-content-between mt-2'>
                                    <Text
                                        sx={() => ({
                                            fontSize: 14,
                                            color: '#888686'
                                        })}
                                    >
                                        Platform Fee
                                    </Text>
                                    <p className='fs-14 mb-0'>{`${getCurrencyText(complex?.info?.country)}${parseFloat(booking?.info?.platform_fee).toFixed(2)}`}</p>
                                </Box>
                                <Box className='d-flex justify-content-between mt-2'>
                                    <Text className='fs-14'
                                        sx={() => ({
                                            color: '#888686'
                                        })}
                                    >
                                        {`Merchant Fee(${getFeeFromBookingAmount(booking?.info?.merchant_fees, booking?.booking_amount)}%)`}
                                    </Text>
                                    <p className='fs-14 mb-0'>{`${getCurrencyText(complex?.info?.country)}${parseFloat(booking?.info?.merchant_fees).toFixed(2)}`}</p>
                                </Box>
                                <Box className='d-flex justify-content-between mt-2'>
                                    <Text className='fs-14'
                                        sx={() => ({
                                            color: '#888686'
                                        })}
                                    >
                                        {`GST(${getFeeFromBookingAmount(booking?.info?.merchant_gst, booking?.booking_amount)}%)`}
                                    </Text>
                                    <p className='fs-14'>{`${getCurrencyText(complex?.info?.country)}${parseFloat(booking?.info?.merchant_gst).toFixed(2)}`}</p>
                                </Box>
                                <hr className='mt-0 mb-1' />
                                <Box className='d-flex justify-content-between my-2'>
                                    <p className='fs-16 fw-600'>Total Amount</p>
                                    <p className='fs-16 fw-600'>
                                        {`${getCurrencyText(complex?.info?.country)}${parseFloat(
                                            Number(booking?.final_amount) +
                                            Number(booking?.info?.merchant_gst) +
                                            Number(booking?.info?.merchant_fees) +
                                            Number(booking?.info?.platform_fee)
                                        ).toFixed(2)}`}
                                    </p>
                                </Box>
                            </Box>
                            <List>
                                <List.Item>Please double check your booking details. </List.Item>
                                <List.Item>No refund or cancellation allowed after booking is made.</List.Item>
                            </List>
                            <Box
                                sx={() => ({
                                    marginTop: 20
                                })}
                            >
                                <p className='fs-18 fw-600 booking-title mb-4'>User Details</p>
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className='fs-14 fw-600'>User name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={username}
                                            onChange={(e) => handleUsernameChange(e)}
                                            placeholder="Enter name"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-600'>Phone number</Form.Label>
                                        <InputGroup className='rounded mb-4'>
                                            <InputGroup.Text className='p-0 border-0'>
                                                <Form.Select
                                                    className='border-end-0'
                                                    onChange={(e) => {
                                                        setCountryCode(e?.target?.value);
                                                    }}
                                                >
                                                    <option value="+60">+60</option>
                                                    <option value="+61">+61</option>
                                                    <option value="+65">+65</option>
                                                </Form.Select>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className='border-start-0'
                                                type='tel'
                                                value={phoneNumber}
                                                onChange={(e) => handlePhoneNumber(e)}
                                                placeholder="Phone number"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className='fs-14 fw-600'>Email</Form.Label>
                                        <Form.Control
                                            type='email'
                                            value={email}
                                            onChange={(e) => handleEmail(e)}
                                            placeholder="Email address"
                                        />
                                    </Form.Group>
                                    {emailNotValidated && (
                                        <Text
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '300',
                                                fontStyle: 'italic',
                                                lineHeight: 1.56,
                                                color: '#d43d40',
                                                marginTop: 20,
                                            }}
                                        >
                                            *Please enter a valid email to <br />
                                            receive your e-receipt.
                                        </Text>
                                    )}
                                    {notValidated && (
                                        <Text
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '300',
                                                fontStyle: 'italic',
                                                lineHeight: 1.56,
                                                color: '#d43d40',
                                                marginTop: 20,
                                            }}
                                        >
                                            *Please enter a valid Phone Number
                                        </Text>
                                    )}
                                    {usernameNotValidated && (
                                        <Text
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '300',
                                                fontStyle: 'italic',
                                                lineHeight: 1.56,
                                                color: '#d43d40',
                                                marginTop: 20,
                                            }}
                                        >
                                            *Please enter your name
                                        </Text>
                                    )}

                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            margin: '30px 0',
                                        }}
                                    >
                                        <Text
                                            sx={() => ({
                                                fontSize: 20,
                                                fontWeight: 600,
                                                color: '#1C1C1C'
                                            })}
                                        >
                                            Total Amount
                                        </Text>
                                        <Text
                                            sx={() => ({
                                                fontSize: 20,
                                                fontWeight: 600,
                                                color: '#FF0000'
                                            })}
                                        >
                                            {`${getCurrencyText(complex?.info?.country)}${parseFloat(
                                                Number(booking?.final_amount) +
                                                Number(booking?.info?.merchant_gst) +
                                                Number(booking?.info?.merchant_fees) +
                                                Number(booking?.info?.platform_fee)
                                            ).toFixed(2)}`}
                                        </Text>
                                    </Box>
                                    {isLockedBooking && (
                                        <Box className='text-end'>
                                            <Button
                                                type="submit"
                                                style={{
                                                    backgroundColor: '#FA5000',
                                                    '&:hover': {
                                                        backgroundColor: '#FA5000'
                                                    }
                                                }}
                                                disabled={!isLockedBooking || phoneNumber === '' || phoneNumber?.length < 8 || email === ''}
                                            >
                                                Pay Now
                                            </Button>
                                        </Box>
                                    )}
                                </form>
                            </Box>
                        </Box>
                    )}
                </Container>
            </Container>
        </Container>
        <Footer />
    </Container>
}

export default Booking;
