import React, { useState, useEffect } from 'react';
import { Container, Group, Box, Image, Card, Button } from '@mantine/core';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { IoArrowBackSharp } from "react-icons/io5";
import Swal from 'sweetalert2';
import classNames from 'classnames';

// Custom Components
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Footer from '../../components/Footer/Footer';

// API-Service
import { facilitiesListService } from '../../service/api.service';

// Utility Service
import { getCurrencyText } from '../../helpers';

// Images
import { placeHolder } from '../../assets/images';

const Facilities = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [complexImage, setComplexImage] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [currency, setCurrency] = useState('MYR');

    let uuid = '';

    if (searchParams?.get('machineUUID')) {
        uuid = searchParams?.get('machineUUID') || location?.state?.uuid;
    } else if (searchParams?.get('auth')) {
        uuid = searchParams?.get('auth') || location?.state?.uuid;
    } else {
        uuid = location?.state?.uuid;
    }

    localStorage.setItem('machineUUID', uuid);
    localStorage.setItem('isAuth', !!searchParams?.get('auth'));

    const isAuth = localStorage.getItem('isAuth' || false);

    /**
        * @function fetchFacilities
        * @params
        * @description used to get facilities list
    */
    const fetchFacilities = async () => {
        try {
            const result = await facilitiesListService();
            if (result?.data?.success === 1) {
                setLoading(false);
                setFacilities(result?.data?.data?.sports_facilities);
                const imageArray = result?.data?.data?.sports_complex.images;
                setCurrency(getCurrencyText(result?.data?.data?.sports_complex?.info?.country));
                setComplexImage(`${imageArray[0]}`);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Error!',
                    text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#FA5000',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Error!',
                text: error || error?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    useEffect(() => {
        if (searchParams?.get('machineUUID') || searchParams?.get('auth')) {
            fetchFacilities();
        }
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    return <Container fluid className='px-0 min-vh-100 d-flex flex-column' size="md">
        <PageTitle uuid={uuid} title="Facilities" logo={complexImage} />
        {(!uuid || !loading) && <p className='fs-40 fw-500 text-center mx-auto mb-auto'>Empty/Invalid UUID</p>}
        {!isAuth && <IoArrowBackSharp role='button' size={30} className='text-dark' onClick={() => window?.kioskVEHome()} />}
        {uuid && loading ? <Loader /> : <Container fluid className='w-100 px-3 px-sm-5'>
            <Group
                className={classNames({
                    'px-3 px-sm-5 py-4 justify-content-center align-items-stretch': facilities?.length > 0
                })}
                sx={(_) => ({
                    margin: '20px auto 50px auto',
                    backgroundColor: '#E5E8EB',
                    '@media (maxWidth: 992px)': {
                        justifyContent: 'center'
                    }
                })}
            >
                {facilities?.map((item) => (
                    <Link
                        className={classNames({
                            'pe-none': item.status !== 1
                        })}
                        style={{
                            textDecoration: 'none',
                        }}
                        to={`${item.status === 1 ? '/scheduler' : `?machineUUID=${uuid}`}`}
                        state={{
                            facilitiesTitle: item?.name,
                            facilities,
                            courts: item?.courts,
                            timeSlots: item?.timeSlots || [],
                            id: item?.id,
                            uuid,
                            is30MinAllowed: item?.booking_window === '30.0',
                            timings: item.timings,
                            minBookingHours: Number(item.min_booking_hour),
                            currency,
                            complexImage,
                            maxBookingDays: item.max_booking_days,
                        }}
                    >
                        <Box
                            key={item?.id}
                            sx={() => ({
                                // width: '100%',
                                width: '352px',
                                height: '100%',
                                maxWidth: '352px',
                                margin: '15px auto',
                                '@media (max-width: 990px)': {
                                    width: '100%',
                                },
                                '@media (max-width: 992px)': {
                                    width: '100%',
                                },
                                '@media (max-width: 480px)': {
                                    width: '100%',
                                },
                                '@media (max-width: 351px)': {
                                    width: '100%',
                                },
                            })}
                        >
                            <Card
                                className='d-flex flex-column justify-content-between align-items-center h-100'
                                style={{
                                    borderRadius: 10
                                }}
                                shadow="sm"
                                p="md"
                            >
                                <Card.Section>
                                    <Image
                                        className='facility-image'
                                        src={item?.images[0] ? `${item?.images[0]}` : placeHolder}
                                        alt=""
                                    />
                                </Card.Section>
                                <Group className='d-flex flex-column justify-content-between align-items-center text-center mt-3 mb-2' position="center">
                                    <p className='fs-25 fw-500'>{item?.name}</p>
                                    <div>
                                        <Button className='mt-4 py-2 text-decoration-none' disabled={item.status !== 1} sx={{
                                            backgroundColor: '#FA5000',
                                            '&:hover': {
                                                backgroundColor: '#FA5000'
                                            }
                                        }}>
                                            <Link
                                                className={classNames({
                                                    'pe-none': item.status !== 1
                                                })}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                                to={`${item.status === 1 ? '/scheduler' : `?machineUUID=${uuid}`}`}
                                                state={{
                                                    facilitiesTitle: item?.name,
                                                    facilities,
                                                    courts: item?.courts,
                                                    timeSlots: item?.timeSlots || [],
                                                    id: item?.id,
                                                    uuid,
                                                    is30MinAllowed: item?.booking_window === '30.0',
                                                    timings: item.timings,
                                                    minBookingHours: Number(item.min_booking_hour),
                                                    currency,
                                                    complexImage,
                                                    maxBookingDays: item.max_booking_days,
                                                }}
                                            >
                                                Book Now
                                            </Link>
                                        </Button>
                                    </div>
                                </Group>
                            </Card>
                        </Box>
                    </Link>
                ))}
            </Group>
        </Container>
        }
        <Footer />
    </Container>
}

export default Facilities;
