import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

// Custom Components
import Footer from '../../components/Footer/Footer';

// API Service
import { allVariableService } from '../../service/api.service';

const TermsAndCondition = () => {
    const navigate = useNavigate();

    const [termsAndConditions, setTermsAndConditions] = useState()

    /**
        * @function fetchTermsAndConditions
        * @params
        * @description fetch the content of terms and conditions
    */
    const fetchTermsAndConditions = async () => {
        try {
            const result = await allVariableService();
            if (result?.data?.success === 1) {
                setTermsAndConditions(result?.data?.data?.terms_condition)
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: result?.response?.data?.message || 'Something went wrong',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#FA5000',
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: error?.response?.data?.message || error || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    }

    useEffect(() => {
        fetchTermsAndConditions();
        // eslint-disable-next-line
    }, [])

    return <Container fluid className='px-0 min-vh-100 d-flex flex-column' size="md">
        <Container className='flex-grow-1 my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <BiArrowBack role="button" size={20} className='position-absolute top-0 start-0 mt-2 ms-2' onClick={() => navigate(-1)} />
                    <div className='mt-5'>
                        {termsAndConditions && parse(termsAndConditions)}
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </Container>
};

export default TermsAndCondition;