import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

// Images
import { logo } from '../../assets/images';

const Footer = () => {
    const isAuth = localStorage.getItem('isAuth' || false);
    const machineUUID = localStorage.getItem('machineUUID');

    return <footer className='border-top border-secondary'>
        <Container className='py-3'>
            <Row className='text-center text-md-start'>
                <Col lg={2}>
                    <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center'>
                        <Link to={`/${isAuth && JSON.parse(isAuth?.toLowerCase()) ? `?auth=${machineUUID}` : `?machineUUID=${machineUUID}`}`} className='d-contents ps-0 ps-lg-2-point-5'>
                            <img className='img-fluid' src={logo} alt='logo' />
                        </Link>
                    </Row>
                </Col>
                <Col lg={7}>
                    <Row className='h-100'>
                        <Col xs={5} lg={5} className='d-none d-lg-flex flex-lg-row justify-content-end align-items-start align-items-lg-center text-center pe-0'>
                            <Link to='/about-us' className='text-decoration-none mt-2 me-4'>
                                <span className='fw-bold text-secondary mt-2 me-4'>About Us</span>
                            </Link>
                        </Col>
                        <Col xs={12} lg={7} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-center justify-content-lg-start align-items-center text-center ps-0'>
                            <Link to='/about-us' className='d-lg-none text-decoration-none mt-2 me-lg-4'>
                                <span className='fw-bold text-secondary mt-2 me-lg-4'>About Us</span>
                            </Link>
                            <Link to='/privacy-policy' className='text-decoration-none mt-2 me-lg-4'>
                                <span className='fw-bold text-secondary mt-2 me-lg-4'>Privacy policy</span>
                            </Link>
                            <Link to='/terms-and-condition' className='text-decoration-none mt-2 me-lg-4'>
                                <span className='fw-bold text-secondary mt-2 me-lg-4'>Terms & Conditions</span>
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                    <div className='d-flex flex-column align-items-center align-items-lg-start'>
                        <span className='fw-400 text-secondary text-start text-lg-center mt-2'>© {new Date().getFullYear()} AFA Community</span>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>;
};

export default Footer;