import React, { } from "react";
import Spinner from "react-spinkit";
import { Box } from '@mantine/core';

const Loader = () =>
    <Box
        sx={() => ({
            padding: '50px 0',
            display: 'flex',
            flexGrow: '1',
            justifyContent: 'center',
            alignItems: 'center',
            '.sk-spinner': {
                'div': {
                    '&:before': {
                        background: '#FA5000'
                    }
                }
            }
        })}
    >
        <Spinner name="circle" style={{
            width: 70,
            height: 70
        }} />
    </Box>


export default Loader;
