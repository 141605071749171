import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@mantine/core';
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import classNames from "classnames";

// Custom Components
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Footer from '../../components/Footer/Footer';

// API-Service
import { facilitiesListService, bookingReceiptService } from "../../service/api.service";

// Images
import { appstore, book1, book2, check, discount, playstore } from '../../assets/images';

const BookingSuccessful = () => {
    const { hash } = useParams();
    const uuid = localStorage.getItem("machineUUID");

    const [loading, setLoading] = useState(true);
    const [complexImage, setComplexImage] = useState('');
    const [receiptUrl, setReceiptUrl] = useState();

    /**
        * @function fetchFacilities
        * @params
        * @description used to get facilities list
    */
    const fetchFacilities = async () => {
        try {
            const result = await facilitiesListService();
            if (result?.data?.success === 1) {
                setLoading(false);
                const imageArray = result?.data?.data?.sports_complex.images;
                setComplexImage(`${imageArray[0]}`);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Error!',
                    text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#FA5000',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Error!',
                text: error || error?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    const fetchReceipt = async () => {
        try {
            const result = await bookingReceiptService(hash)
            if (result?.data?.success === 1) {
                setReceiptUrl(result?.data?.data?.url)
            } else {
                setReceiptUrl()
                Swal.fire({
                    title: 'Error!',
                    text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#FA5000',
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: error?.response?.data?.message || error || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    }

    useEffect(() => {
        if (hash) {
            fetchReceipt()
        }
    }, [hash]);

    useEffect(() => {
        if (uuid) {
            fetchFacilities();
        } else {
            setLoading(false);
        }
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    if (loading) {
        return <Loader />
    }

    return <Container className='min-vh-100 overflow-hidden px-0' fluid style={{
        backgroundColor: '#F7F7F7'
    }}>
        <PageTitle uuid={uuid} logo={complexImage} />
        <Row>
            <Col xs={10} lg={6} className='d-flex flex-column align-items-start mx-auto pb-4'>
                <div className='d-flex flex-column flex-md-row justify-content-center align-items-center w-100'>
                    <div className='d-flex justify-content-center align-items-center rounded-5 bg-white px-3 px-md-4 py-2 mb-2 mb-md-0 me-md-5'>
                        <img className='img-fluid me-3' src={check} alt='check' />
                        <p className='fs-18 fw-600 text-center mb-0'
                            style={{
                                color: '#101828'
                            }}
                        >
                            Your booking is confirmed!
                        </p>
                    </div>
                    <Link className={classNames("rounded", { "pe-none opacity-25": !receiptUrl })} to={`${receiptUrl || "/"}`} target="_blank" style={{
                        background: "#3EA699"
                    }}>
                        <Button
                            sx={{
                                backgroundColor: '#3EA699',
                                '&:hover': {
                                    backgroundColor: '#3EA699'
                                }
                            }}
                            disabled={!receiptUrl}
                        >
                            Download Invoice
                        </Button>
                    </Link>
                </div>
                <img className='img-fluid w-100 mt-3' src={discount} alt='discount' />
            </Col>
            <Col xs={12}>
                <img className='img-fluid w-100' src={book1} alt='book1' />
            </Col>
            <Col className='position-relative' xs={12}>
                <img className='img-fluid w-100' src={book2} alt='book2' />
                <Link target="_blank" to="https://play.google.com/store/apps/details?id=com.afausers">
                    <img className='position-absolute img-fluid play-store-image' src={playstore} alt='play-store' />
                </Link>
                <Link target="_blank" to="https://apps.apple.com/my/app/afa-meet-people-play-sports/id1526420132">
                    <img className='position-absolute img-fluid app-store-image' src={appstore} alt='app-store' />
                </Link>
            </Col>
        </Row>
        <Footer />
    </Container>
}

export default BookingSuccessful;
