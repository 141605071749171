import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Scroll Component
import ScrollToTop from './components/scrolltotop';

// Pages
import Facilities from './pages/Facilities/Facilities';
import Scheduler from './pages/Scheduler/Scheduler';
import Booking from './pages/Booking/Booking';
import BookingSuccessful from './pages/BookingSuccessful/BookingSuccessful';
import BookingError from './pages/BookingError/BookingError';
import Payment from './pages/Payment/Payment';
import AboutUs from './pages/AboutUs/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition/TermsAndCondition';

const App = () => {
    return <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Facilities />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/success/:hash" element={<BookingSuccessful />} />
            <Route path="/error" element={<BookingError />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        </Routes>
    </BrowserRouter>
}

export default App;
